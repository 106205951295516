<template>
  <div class="so-color container-fluid min-vh-100">
    <Navigation :dark="true">
      <ul class="navbar-nav ---ms-auto mb-2 mb-lg-0">
        <li class="nav-item mx-2">
          <router-link :to="{ name: 'Playlists' }">
            Playlists
          </router-link>
        </li>
        <li class="nav-item mx-2">
          <router-link :to="{ name: 'Ingredients' }">
            Ingredients
          </router-link>
        </li>
        <li class="nav-item mx-2">
          <router-link :to="{ name: 'User.List' }">
            Users
          </router-link>
        </li>
      </ul>
    </Navigation>

    <router-view />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
export default {
  name: "ServiceApp",
  components: {
    Navigation,
  },
};
</script>
<style scoped>
.so-color {
  background-color: var(--so-sand) !important; 
  color: #000000;
}
</style>
<style>
a, a:hover, a:focus, a:active { 
  color: #000000;
}
</style>
